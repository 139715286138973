import React from 'react';
import './Footer.css';

class Footer extends React.Component {
    render() {
        return(
            <div className="footer">
                <h6>&copy;AJLily</h6>
            </div>
        );
    }
}

export default Footer;